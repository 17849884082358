
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "orderingOffersChooseMenuItem": {
      "id": "ordering_offers.choose_menu_item",
      "defaultMessage": "Choose Menu Item"
    },
    "orderingOffersAll": {
      "id": "ordering_offers.all",
      "defaultMessage": "All"
    },
    "orderingOffersAllLocations": {
      "id": "ordering_offers.all_locations",
      "defaultMessage": "All Locations"
    },
    "orderingOffersNoPreferredLocation": {
      "id": "ordering_offers.no_preferred_location",
      "defaultMessage": "No Preferred Location"
    },
    "orderingOffersApply": {
      "id": "ordering_offers.apply",
      "defaultMessage": "What do you want this offer to apply to?"
    },
    "orderingOffersMenu": {
      "id": "ordering_offers.menu",
      "defaultMessage": "Menu"
    },
    "orderingOffersOnlineOrdering": {
      "id": "ordering_offers.online_ordering",
      "defaultMessage": "Online Ordering"
    },
    "orderingOffersDineIn": {
      "id": "ordering_offers.dine_in",
      "defaultMessage": "On Premise Dine-In"
    },
    "orderingOffersLocation": {
      "id": "ordering_offers.location",
      "defaultMessage": "Location"
    },
    "orderingOffersMenuSection": {
      "id": "ordering_offers.menu_section",
      "defaultMessage": "Menu Section"
    },
    "orderingOffersItem": {
      "id": "ordering_offers.item",
      "defaultMessage": "Dish"
    },
    "orderingOffersMenuPlaceholder": {
      "id": "ordering_offers.menu_placeholder",
      "defaultMessage": "Choose menu"
    },
    "orderingOffersMenuSectionPlaceholder": {
      "id": "ordering_offers.menu_section_placeholder",
      "defaultMessage": "Choose menu section"
    },
    "orderingOffersItemPlaceholder": {
      "id": "ordering_offers.item_placeholder",
      "defaultMessage": "Choose dish"
    },
    "orderingOffersSmartMessageDescription": {
      "id":"ordering_offers.smart_message_description",
      "defaultMessage": "This message is automatically triggered and sent to a follower based on a specific action or inaction: e.g. After a follower places an order, send message to remind them to leave a review.",
    },
    "orderingOffersOfferTitle": {
      "id": "ordering_offers.offer_title",
      "defaultMessage": "Offer Title"
    },
    "orderingOffersMassMessageDescription": {
      "id":"ordering_offers.mass_message_description",
      "defaultMessage": "Offers created for dynamic messages can be used in different types of messages, including mass messages, text messages, social media posts, and print mail.",
    },
    "orderingOffersTabHistory": {
      "id": "ordering_offers.tabs.history",
      "defaultMessage": "History"
    },
    "orderingOffersTabLiveOffers": {
      "id": "ordering_offers.tabs.live_offers",
      "defaultMessage": "Live Offers"
    },
    "orderingOffersTabOfferPromotions": {
      "id": "ordering_offers.tabs.offer_promotions",
      "defaultMessage": "Offer Promotions"
    },
    "orderingOffersTabLibrary": {
      "id": "ordering_offers.tabs.library",
      "defaultMessage": "Ready"
    },
    "orderingOffersPreview": {
      "id": "ordering_offers.preview",
      "defaultMessage": "Preview"
    },
    "orderingOffersDestroyConfirm": {
      "id": "ordering_offers.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this offer? Existing codes will be invalidated."
    },
    "orderingOffersDestroyConfirmTitle": {
      "id": "ordering_offers.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "orderingOffersDestroy": {
      "id": "ordering_offers.destroy",
      "defaultMessage": "Remove Online Offer"
    },
    "orderingOffersDeactivate": {
      "id": "ordering_offers.deactivate",
      "defaultMessage": "Deactivate"
    },
    "orderingOffersDeactivateMessage": {
      "id": "ordering_offers.deactivate_message",
      "defaultMessage": "If this offer has been sent out to your followers and you deactivate it, they will no longer be able to use it. Once an offer has been deactivated, it cannot be used again."
    },
    "orderingOffersDeactivateTitle": {
      "id": "ordering_offers.deactivate_title",
      "defaultMessage": "Deactivate Online Offer"
    },
    "orderingOffersDetach": {
      "id": "ordering_offers.detach",
      "defaultMessage": "Detach"
    },
    "orderingOffersDetachMessage": {
      "id": "ordering_offers.detach_message",
      "defaultMessage": "This offer will no longer be attached to the smart message."
    },
    "orderingOffersDetachTitle": {
      "id": "ordering_offers.detach_title",
      "defaultMessage": "Are you sure you want to detach this offer?"
    },
    "orderingOffersPromoteOffer": {
      "id": "ordering_offers.promote_offer",
      "defaultMessage": "Promote Offer"
    },
    "orderingOffersStopPromoting": {
      "id": "ordering_offers.stop_promoting",
      "defaultMessage": "Stop Promoting"
    },
    "orderingOffersStopMarketingMessage": {
      "id": "ordering_offers.stop_marketing_message",
      "defaultMessage": "By saving this change, this offer will no longer be promoted."
    },
    "orderingOffersStopMarketingMessageDetailed": {
      "id": "ordering_offers.stop_marketing_message_detailed",
      "defaultMessage": "By saving this change, this offer will no longer be promoted on {marketingLocation}"
    },
    "orderingOffersStopMarketingTitle": {
      "id": "ordering_offers.stop_marketing_title",
      "defaultMessage": "Stop Promoting Offer"
    },
    "orderingOffersTipsTitle": {
      "id": "ordering_offers.tips.title",
      "defaultMessage": "How to Use Online Offers"
    },
    "orderingOffersTipsCreateTitle": {
      "id": "ordering_offers.tips.create.title",
      "defaultMessage": "Step 1: Create an offer"
    },
    "orderingOffersTipsCreateDescription": {
      "id": "ordering_offers.tips.create.description",
      "defaultMessage": "Once an offer is created, it will be listed in the \"Ready\" tab. Note: Offers in the \"Ready\" tab need to be activated before you can use them."
    },
    "orderingOffersTipsActivateTitle": {
      "id": "ordering_offers.tips.activate.title",
      "defaultMessage": "Step 2: Activate your offer"
    },
    "orderingOffersTipsActivateDescription": {
      "id": "ordering_offers.tips.activate.description",
      "defaultMessage": "When you're ready to use your offer, click on \"ACTIVATE\". Once activated, your offer will be ready to be used in emails, text or social media. Your newly activated offer will now be listed in the \"Live Offers\" tab."
    },
    "orderingOffersTipsSendTitle": {
      "id": "ordering_offers.tips.send.title",
      "defaultMessage": "Step 3: Send your offer"
    },
    "orderingOffersTipsSendDescription": {
      "id": "ordering_offers.tips.send.description",
      "defaultMessage": "Email your offer to your guests by clicking \"SEND\" or attach it to a smart message by clicking \"ATTACH\". Send your offer via text or social media by copy/pasting the offer code into your message."
    },
    "orderingOffersActivationModalTitle": {
      "id": "ordering_offers.activation_modal.title",
      "defaultMessage": "What would you like to do?"
    },
    "orderingOffersActivatationModalHeaderSmartMessageMethod": {
      "id": "ordering_offers.activation_modal.header.smart_message_method",
      "defaultMessage": "Attach & Activate Offer"
    },
    "orderingOffersActivatationModalDescriptionSmartMessageMethod": {
      "id": "ordering_offers.activation_modal.description.smart_message_method",
      "defaultMessage": "You will be able to attach the offer and activate it."
    },
    "orderingOffersActivatationModalHeaderMassMessageMethod": {
      "id": "ordering_offers.activation_modal.header.mass_message_method",
      "defaultMessage": "Promote & Activate Offer"
    },
    "orderingOffersActivatationModalDescriptionMassMessageMethod": {
      "id": "ordering_offers.activation_modal.description.mass_message_method",
      "defaultMessage": "You will be able to promote the offer and activate it."
    },
    "orderingOffersActivatationModalActivateOnlyCardTitle": {
      "id": "ordering_offers.activation_modal.activate_only_card.title",
      "defaultMessage": "Activate Only"
    },
    "orderingOffersActivatationModalActivateOnlyCardDescription": {
      "id": "ordering_offers.activation_modal.activate_only_card.description",
      "defaultMessage": "Once an offer is activated, you will not be able to edit it."
    },
  }
);
export default defaultMessages;
