import type { OrderingEventDetailsPageQuery_orderingEvent_OrderingEvent as OrderingEvent } from '~/libs/gql/queries/ordering_events/orderingEventDetailsPageQuery.generated';

export type AdminOrderingEventManagementState = {
  showFullScreenStepper: boolean
  eventIdToEdit: number | null
  eventIdToDuplicate: number | null
  showDeleteModal: boolean
  eventIdToDelete: number | null
  showExportModal: boolean
  eventName: string
  selectedLocationId: number | null
  selectedMenuId: number | null
  showNoticeModal: boolean
}

/** @internal this export is for tests */
export const initialOrderingEventManagementState: AdminOrderingEventManagementState = {
  eventIdToDelete: null,
  eventIdToDuplicate: null,
  eventIdToEdit: null,
  eventName: '',
  selectedLocationId: null,
  selectedMenuId: null,
  showDeleteModal: false,
  showExportModal: false,
  showFullScreenStepper: false,
  showNoticeModal: false,
};

export type AdminOrderingEventManagementEvent =
  | { type: 'openFullScreenStepper' }
  | { type: 'closeFullScreenStepper' }
  | { type: 'openDeleteModal', context: { event: OrderingEvent } }
  | { type: 'closeDeleteModal' }
  | { type: 'startDuplicate', context: { event: OrderingEvent } }
  | { type: 'startEdit', context: { event: OrderingEvent } }
  | { type: 'openExportModal', context: { event: OrderingEvent } }
  | { type: 'closeExportModal' }
  | { type: 'clickMenu', context: { menuId: number | null } }
  | { type: 'selectLocation', context: { locationId: number | null } }
  | { type: 'openNoticeModal', context: { event: OrderingEvent } }
  | { type: 'closeNoticeModal' }

export const adminOrderingEventManagementReducer = (state: AdminOrderingEventManagementState, event: AdminOrderingEventManagementEvent): AdminOrderingEventManagementState => {
  const newState = { ...state };
  switch (event.type) {
    case 'openFullScreenStepper':
      newState.showFullScreenStepper = true;
      return newState;
    case 'closeFullScreenStepper':
      newState.showFullScreenStepper = false;
      newState.eventIdToEdit = null;
      newState.eventIdToDuplicate = null;
      newState.selectedLocationId = null;
      newState.selectedMenuId = null;
      return newState;
    case 'openDeleteModal':
      if (event.context.event) {
        newState.showDeleteModal = true;
        newState.eventIdToDelete = event.context.event.id;
      }
      return newState;
    case 'closeDeleteModal':
      newState.showDeleteModal = false;
      newState.eventIdToDelete = null;
      return newState;
    case 'startDuplicate':
      if (event.context.event) {
        newState.showFullScreenStepper = true;
        newState.eventIdToDuplicate = event.context.event.id;
        newState.selectedLocationId = event.context.event.location?.id || -1;
      }
      return newState;
    case 'startEdit':
      if (event.context.event) {
        newState.showFullScreenStepper = true;
        newState.eventIdToEdit = event.context.event.id;
        newState.selectedLocationId = event.context.event.location?.id || -1;
      }
      return newState;
    case 'openNoticeModal':
      newState.showNoticeModal = true;
      return newState;
    case 'closeNoticeModal':
      newState.showNoticeModal = false;
      return newState;
    case 'openExportModal':
      if (event.context.event) {
        newState.showExportModal = true;
        newState.eventName = event.context.event.name;
      }
      return newState;
    case 'closeExportModal':
      newState.showExportModal = false;
      newState.eventName = '';
      return newState;
    case 'clickMenu':
      if (event.context.menuId) {
        newState.selectedMenuId = event.context.menuId;
      }
      return newState;
    case 'selectLocation':
      if (event.context.locationId) {
        newState.selectedLocationId = event.context.locationId;
      }
      return newState;
    default:
      return newState;
  }
};
