import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ApolloProvider } from '~/lazy_apollo/client';
import { BrowserRouter } from 'react-router-dom';
import 'intl';
import 'intl/locale-data/jsonp/en-US';
import { IntlProvider } from 'react-intl';
import { CssBaseline } from '@material-ui/core';

import { writeQueries } from '../utils/apollo';
import { initApolloWithWebsockets } from '../utils/apolloWithWebsockets';
import { initAdminStore } from '../utils/initStore';
import { ThemeProvider } from '../utils/withStyles';
import { SnackbarProvider } from '../utils/withSnackbar';
import { toggleClass } from '../utils/dom';
import { setApolloClient } from '../utils/apolloClient';

import AdminSwitch from './AdminSwitch';
import WindowProvider from '../shared/WindowProvider';
import WindowSizeProvider from '../shared/WindowSizeProvider';
import { AHLevelProvider } from '../consumer/shared/AccessibleHeading';
import { setAppStaticProps } from '../utils/appStaticProps';

class AdminApp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.locale = 'en';
    this.state = {
      render: false,
    };
  }

  // Delay rendering so that the Popmenu splash screen shows for moment
  componentDidMount() {
    setTimeout(() => {
      const loadingRef = document.getElementById('popmenu-loading');
      if (loadingRef) {
        toggleClass(loadingRef, 'done', true);
      }
      setTimeout(() => {
        if (loadingRef) {
          loadingRef.parentNode.removeChild(loadingRef);
        }
        this.setState({ render: true });
      }, 150);
    }, 1500);
  }

  render() {
    const { props, state, locale } = this;
    if (!state.render) {
      return null;
    }
    setAppStaticProps(props);

    // Init store
    const client = initApolloWithWebsockets({
      operationStoreEnabled: props.operationStoreEnabled,
      url: props.graphqlUrl,
      websocketUrl: props.websocketUrl,
    });
    setApolloClient(client);
    writeQueries(props.preloadQueries, client);

    // Loads Apollo Client messages for errors that are raised only in a development environment.
    if (process.env.NODE_ENV !== 'production') {
      console.log('[POPMENU] Apollo Client Error Messages Loaded');
      import(/* webpackChunkName: "apolloClientDev" */ '~/lazy_apollo/client/dev').then(({ loadDevMessages, loadErrorMessages }) => {
        loadDevMessages();
        loadErrorMessages();
      });
    }

    const store = initAdminStore();

    console.log(`[POPMENU] Init Admin ${process.env.IS_INSTRUMENTED_BUILD ? 'with' : 'without'} instrumented JS`);

    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <IntlProvider locale={locale}>
            <BrowserRouter>
              <WindowSizeProvider desktop={props.desktop} tablet={props.tablet} mobile={props.mobile}>
                <WindowProvider shouldPauseScrollListener>
                  <ThemeProvider>
                    <CssBaseline />
                    <SnackbarProvider>
                      <AHLevelProvider>
                        <AdminSwitch />
                      </AHLevelProvider>
                    </SnackbarProvider>
                  </ThemeProvider>
                </WindowProvider>
              </WindowSizeProvider>
            </BrowserRouter>
          </IntlProvider>
        </ApolloProvider>
      </Provider>
    );
  }
}

AdminApp.defaultProps = {
  websocketUrl: null,
};

AdminApp.propTypes = {
  desktop: PropTypes.bool.isRequired,
  graphqlUrl: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  operationStoreEnabled: PropTypes.bool.isRequired,
  preloadQueries: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.object,
    query: PropTypes.string,
    variables: PropTypes.object,
  })).isRequired,
  requestPath: PropTypes.string.isRequired,
  tablet: PropTypes.bool.isRequired,
  websocketUrl: PropTypes.string,
};

export default AdminApp;
