import type { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import { differenceInSeconds } from 'date-fns';

export type DateRangeOption = 'today' | 'tomorrow' | 'thisWeek' | 'allTime' | 'past' | 'week' | 'month'
export type ViewLayoutType = 'list' | 'calendar'
export type DateRange = [Date | undefined, Date | undefined]
export type ViewStatusType = 'active' | 'history' | 'search'
export type LocationState = { dateRange: DateRangeOption, viewLayoutType: ViewLayoutType }

// exported for testing purposes only when mocking state
export type OrderManagementState = {
  pendingPickupOrdersCount: number
  pendingDeliveryOrdersCount: number
  pendingTotalOrdersCount: number
  dateRange: DateRangeOption
  viewLayoutType: ViewLayoutType
  viewStatusType: ViewStatusType
  showOrderExportForm: boolean,
  showOrderExportSuccess: boolean,
  showOrderPanel: boolean,
  showQuickActions: boolean
  searchFor: string
  sorting: ColumnSort[],
  filters: ColumnFilter[]
  remainingDelaySeconds: number
  delayUntil: string | null
  effects: string[]
  showTimeEstimateModal: boolean
}

type ClickDateRangeOptionContext = { dateRange: DateRangeOption }
type ClickViewLayoutToggleContext = { viewLayoutType: ViewLayoutType }
type clickViewStatusTypeContext = { viewStatusType: ViewStatusType }
type SaveCustomDateRangeContext = { startDate: Date, endDate: Date }
type InitialLayoutAndDateRangeContext = { dateRange: DateRangeOption, viewLayoutType: ViewLayoutType }
type SetSearchForContext = { reason: 'input', value: string } | { reason: 'clear' | 'reset' }
type SelectFiltersContext = { filters: ColumnFilter[] }
type ClickTableHeaderContext = { sorting: ColumnSort[] }
type SetDelayUntilContext = { closeQuickActions?: boolean, delayUntil?: string | null }
type EffectContext = { effect: string }
export type OrderManagementEvent =
  | { type: 'clickQuickActionsToggle' }
  | { type: 'clickDateRangeOption', context: ClickDateRangeOptionContext }
  | { type: 'clickLayoutToggle', context: ClickViewLayoutToggleContext }
  | { type: 'clickViewStatusType', context: clickViewStatusTypeContext }
  | { type: 'saveCustomDateRange', context: SaveCustomDateRangeContext }
  | { type: 'setSearchFor', context: SetSearchForContext }
  | { type: 'selectFilters', context: SelectFiltersContext }
  | { type: 'selectLocation' }
  | { type: 'clickSeeAllResults' }
  | { type: 'setDelayUntil', context: SetDelayUntilContext }
  | { type: 'unmountOrderManagementPage' }
  | { type: 'clickTableHeader', context: ClickTableHeaderContext }
  | { type: 'tick' }
  | { type: 'toggleOrderExportForm' }
  | { type: 'toggleOrderExportSuccessDialog' }
  | { type: 'toggleOrderPanel' }
  | { type: 'queueEffect', context: EffectContext }
  | { type: 'completeEffect', context: EffectContext }
  | { type: 'initializeViewLayoutType', context: InitialLayoutAndDateRangeContext }
  | { type: 'toggleShowTimeEstimateModal' }

const initialState: OrderManagementState = {
  dateRange: 'today',
  delayUntil: null,
  effects: [],
  filters: [],
  pendingDeliveryOrdersCount: 0,
  pendingPickupOrdersCount: 0,
  pendingTotalOrdersCount: 0,
  remainingDelaySeconds: 0,
  searchFor: '',
  showOrderExportForm: false,
  showOrderExportSuccess: false,
  showOrderPanel: false,
  showQuickActions: false,
  showTimeEstimateModal: false,
  sorting: [{ desc: false, id: 'restaurantDueAt' }],
  viewLayoutType: 'list',
  viewStatusType: 'active',
};

export const adminOrderManagementReducer = (prevState: OrderManagementState = initialState, event: OrderManagementEvent): OrderManagementState => {
  let nextState = { ...prevState };
  switch (event.type) {
    case 'clickQuickActionsToggle':
      nextState.showQuickActions = !prevState.showQuickActions;
      break;
    case 'clickDateRangeOption':
      nextState.dateRange = event.context.dateRange;
      break;
    case 'clickLayoutToggle':
      nextState.viewLayoutType = event.context.viewLayoutType;
      if (event.context.viewLayoutType === 'calendar') {
        nextState.dateRange = 'week';
      }
      if (event.context.viewLayoutType === 'list') {
        nextState.dateRange = 'today';
      }
      nextState.sorting = initialState.sorting;
      nextState.filters = initialState.filters;
      break;
    case 'clickViewStatusType':
      nextState.viewStatusType = event.context.viewStatusType;
      if (event.context.viewStatusType === 'history') {
        nextState.sorting = [{ desc: true, id: 'restaurantDueAt' }];
      } else {
        nextState.sorting = initialState.sorting;
      }
      nextState.filters = initialState.filters;
      break;
    case 'setSearchFor':
      if (event.context.reason === 'input') {
        nextState.searchFor = event.context.value;
      }
      if (event.context.reason === 'clear') {
        nextState.searchFor = '';
        nextState.viewStatusType = 'active';
        nextState.sorting = initialState.sorting;
        nextState.filters = initialState.filters;
      }
      break;
    case 'selectFilters':
      nextState.filters = event.context.filters;
      break;
    case 'selectLocation':
      nextState.sorting = initialState.sorting;
      nextState.filters = initialState.filters;
      break;
    case 'clickSeeAllResults':
      nextState.viewStatusType = 'search';
      nextState.sorting = initialState.sorting;
      nextState.filters = initialState.filters;
      break;
    case 'clickTableHeader':
      nextState.sorting = event.context.sorting;
      break;
    case 'setDelayUntil':
      if (event.context.delayUntil) {
        nextState.delayUntil = event.context.delayUntil;
        nextState.remainingDelaySeconds = differenceInSeconds(new Date(event.context.delayUntil), new Date());
      }
      if (event.context.delayUntil === null) {
        nextState.delayUntil = event.context.delayUntil;
        nextState.remainingDelaySeconds = 0;
      }
      if (event.context.closeQuickActions) {
        nextState.showQuickActions = false;
      }
      break;
    case 'tick':
      if (prevState.delayUntil) {
        nextState.remainingDelaySeconds = differenceInSeconds(new Date(prevState.delayUntil), new Date());
      }
      break;
    case 'toggleOrderExportForm':
      nextState.showOrderExportForm = !prevState.showOrderExportForm;
      if (nextState.showQuickActions) {
        nextState.showQuickActions = false;
      }
      break;
    case 'toggleOrderExportSuccessDialog':
      nextState.showOrderExportSuccess = !prevState.showOrderExportSuccess;
      break;
    case 'toggleOrderPanel':
      nextState.showOrderPanel = !prevState.showOrderPanel;
      break;
    case 'queueEffect':
      if (!nextState.effects.includes(event.context.effect)) {
        nextState.effects = [...nextState.effects, event.context.effect];
      }
      break;
    case 'completeEffect':
      nextState.effects = nextState.effects.filter(effect => effect !== event.context.effect);
      break;
    case 'unmountOrderManagementPage':
      nextState = { ...initialState };
      break;
    case 'initializeViewLayoutType':
      nextState.dateRange = event.context.dateRange;
      nextState.viewLayoutType = event.context.viewLayoutType;
      break;
    case 'toggleShowTimeEstimateModal':
      nextState.showTimeEstimateModal = !prevState.showTimeEstimateModal;
      break;
    default:
      break;
  }
  return nextState;
};
